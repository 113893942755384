import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import {
  FETCH_PROPERTIES_BY_SEASONS_LOADING,
  FETCH_PROPERTIES_BY_SEASONS_SUCCESS,
  FETCH_PROPERTIES_BY_SEASONS_ERROR,
  ADD_PROPERTIES_BY_SEASONS_LOADING,
  ADD_PROPERTIES_BY_SEASONS_SUCCESS,
  ADD_PROPERTIES_BY_SEASONS_ERROR,
  DELETE_PROPERTIES_BY_SEASONS,
  EDIT_PROPERTIES_BY_SEASONS_SUCCESS,
  RESET_ADD_PROPERTIES_BY_SEASONS
} from '../actions/types';

let initialState = {
  data: {
    entities: {},
    result: []
  },
  loading: false,
  error: null,
  addPropertiesBySeasonLoading: false,
  addPropertiesBySeasonError: null,
  addPropertiesBySeasonSuccess: false
};

export const propertiesBySeasonList = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_BY_SEASONS_LOADING:
      return {
        ...state,
        loading: true
      };
    case FETCH_PROPERTIES_BY_SEASONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: { ...state.data, ...action.payload }
      };
    case FETCH_PROPERTIES_BY_SEASONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case EDIT_PROPERTIES_BY_SEASONS_SUCCESS:
      let details = state.data.result.filter((id) => !id.includes(action.payload.id));
      let detailsToBeRemoved = state.data.result.filter((id) => id.includes(action.payload.id));

      let updated = omit(state.data.entities.propertiesBySeasonId, [...detailsToBeRemoved]);
      return {
        ...state,
        addPropertiesBySeasonLoading: false,
        addPropertiesBySeasonError: null,
        addPropertiesBySeasonSuccess: true,
        data: {
          entities: {
            propertiesBySeasonId: {
              ...updated,
              ...action.payload.data.entities.propertiesBySeasonId
            }
          },
          result: uniq([...details, ...action.payload.data.result])
        }
      };
    case ADD_PROPERTIES_BY_SEASONS_SUCCESS:
      return {
        ...state,
        addPropertiesBySeasonLoading: false,
        addPropertiesBySeasonError: null,
        addPropertiesBySeasonSuccess: true,
        data: {
          entities: {
            propertiesBySeasonId: {
              ...state.data.entities.propertiesBySeasonId,
              ...action.payload.entities.propertiesBySeasonId
            }
          },
          result: uniq([...state.data.result, ...action.payload.result])
        }
      };
    case RESET_ADD_PROPERTIES_BY_SEASONS:
      return {
        ...state,
        addPropertiesBySeasonLoading: false,
        addPropertiesBySeasonError: null,
        addPropertiesBySeasonSuccess: false
      };
    case ADD_PROPERTIES_BY_SEASONS_LOADING:
      return {
        ...state,
        addPropertiesBySeasonLoading: true,
        addPropertiesBySeasonError: null,
        addPropertiesBySeasonSuccess: false
      };
    case ADD_PROPERTIES_BY_SEASONS_ERROR:
      return {
        ...state,
        addPropertiesBySeasonLoading: false,
        addPropertiesBySeasonError: action.error,
        addPropertiesBySeasonSuccess: true
      };
    case DELETE_PROPERTIES_BY_SEASONS:
      let itemIdsToDelete = state.data.result.filter((id) => id.includes(action.payload.seasonId));
      let itemIdsToStore = state.data.result.filter((id) => !id.includes(action.payload.seasonId));
      let deletedProperties = omit(state.data.entities.propertiesBySeasonId, [...itemIdsToDelete]);
      return {
        ...state,
        data: {
          entities: { propertiesBySeasonId: deletedProperties },
          result: itemIdsToStore
        }
      };
    default:
      return state;
  }
};
