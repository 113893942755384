import {
  FETCH_VARIETIES_SUCCESS,
  FETCH_VARIETIES_LOADING,
  FETCH_VARIETIES_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  data: []
};

export const varieties = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VARIETIES_SUCCESS:
      return {
        ...initialState,
        data: action.payload
      };

    case FETCH_VARIETIES_LOADING:
      return {
        ...initialState,
        loading: true
      };

    case FETCH_VARIETIES_ERROR:
      return {
        ...initialState,
        error: action.error
      };

    default:
      return state;
  }
};
