import { request } from '../utils/axios';
import { getHeaders, clearAccessToken, getAppHostsInfo } from '../utils/common-methods';
import {
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_LOADING
} from './types';

export function fetchUserProfileLoading() {
  return {
    type: FETCH_USER_PROFILE_LOADING
  };
}

export function fetchUserProfileError(error) {
  return {
    type: FETCH_USER_PROFILE_ERROR,
    payload: error
  };
}

export function fetchUserProfileSuccess(user) {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: user
  };
}

export const fetchUserProfile = (isCache = true) => {
  return (dispatch) => {
    dispatch(fetchUserProfileLoading());
    request
      .get(`/v2/accounts/me`, {
        headers: getHeaders(),
        useCache: isCache
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }
        dispatch(fetchUserProfileSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchUserProfileError(error));
      });
  };
};

export const logoutUser = async () => {
  try {
    const { account } = getAppHostsInfo();
    const myLogoutRequest = new Request(`https://${account}/exit`, {
      method: 'GET',
      mode: 'no-cors',
      credentials: 'include'
    });
    await fetch(myLogoutRequest);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
  clearAccessToken();
};
