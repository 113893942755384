import { createSelector } from 'reselect';
import get from 'lodash/get';

export const getCrops = (state) => get(state, 'crops', {});

export const isCropsLoading = createSelector(getCrops, (crops) => crops.loading);

export const isCropsError = createSelector(getCrops, (crops) => crops.error);

export const getCropsEntities = createSelector(getCrops, (crops) =>
  get(crops, 'data.entities.crops', {})
);

export const getCropsResult = createSelector(getCrops, (crops) => get(crops, 'data.result', []));

export const getAllCrops = createSelector(getCropsEntities, getCropsResult, (entities, results) => {
  return results.map((id) => entities[id]);
});

export const getCropById = createSelector(
  getCropsEntities,
  getCropsResult,
  (_, props) => props.cropId,
  (crops, cropIds, id) => {
    if (cropIds.includes(id)) {
      return crops[id];
    } else {
      return {};
    }
  }
);
