import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { rootReducer } from './index';

const reduxDevTool = '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__';

const isNonProd = process.env.NODE_ENV !== 'production';

const composeEnhancers =
  (typeof window === 'object' && isNonProd && window[reduxDevTool]) || compose;

const middlewares = [thunk];
if (isNonProd) {
  middlewares.push(logger);
}

const enhancer = composeEnhancers(applyMiddleware(...middlewares));
export const store = createStore(rootReducer, enhancer);
