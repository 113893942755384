import {
  FETCH_CROP_DETAILS_SUCCESS,
  FETCH_CROP_DETAILS_LOADING,
  FETCH_CROP_DETAILS_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  data: []
};

export const crops = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CROP_DETAILS_LOADING:
      return {
        ...initialState,
        loading: true
      };
    case FETCH_CROP_DETAILS_SUCCESS:
      return {
        ...initialState,
        data: action.payload
      };
    case FETCH_CROP_DETAILS_ERROR:
      return {
        ...initialState,
        error: action.error
      };
    default:
      return state;
  }
};
