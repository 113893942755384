import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import {
  SET_SEASON_FIELDS_LOADING,
  SET_SEASON_FIELDS_SUCCESS,
  SET_SEASON_FIELDS_ERROR,
  ADD_SEASON_FIELD_SUCCESS,
  ADD_SEASON_FIELD_LOADING,
  ADD_SEASON_FIELD_ERROR,
  DELETE_SEASON_FIELD,
  EDIT_SEASON_FIELD_SUCCESS,
  RESET_ADD_SEASON_FIELD,
  SET_ACTIVE_SEASON_BY_ID_SUCCESS,
  SET_ACTIVE_SEASON_BY_ID_LOADING,
  SET_ACTIVE_SEASON_BY_ID_ERROR
} from '../actions/types';

let initialState = {
  data: {
    entities: {},
    result: []
  },
  loading: false,
  error: null,
  addSeasonFieldLoading: false,
  addSeasonFieldError: null,
  addSeasonFieldSuccess: false,
  activeSeasonByFieldIdLoading: false,
  activeSeasonByFieldIdError: null,
  activeSeasonByFieldId: {
    entities: {},
    result: []
  }
};

export const seasonFields = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEASON_FIELDS_LOADING:
      return {
        ...state,
        loading: true
      };
    case SET_SEASON_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: { ...state.data, ...action.payload }
      };
    case SET_SEASON_FIELDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case SET_ACTIVE_SEASON_BY_ID_SUCCESS:
      return {
        ...state,
        activeSeasonByFieldId: { ...action.payload },
        activeSeasonByFieldIdError: false,
        activeSeasonByFieldIdLoading: false
      };
    case SET_ACTIVE_SEASON_BY_ID_LOADING:
      return {
        ...state,
        activeSeasonByFieldIdLoading: true,
        activeSeasonByFieldIdError: null
      };
    case SET_ACTIVE_SEASON_BY_ID_ERROR:
      return {
        ...state,
        activeSeasonByFieldIdLoading: false,
        activeSeasonByFieldIdError: action.error
      };
    case ADD_SEASON_FIELD_SUCCESS:
      return {
        ...state,
        addSeasonFieldLoading: false,
        addSeasonFieldError: null,
        addSeasonFieldSuccess: true,
        data: {
          entities: {
            seasonFields: {
              ...state.data.entities.seasonFields,
              ...action.payload.entities.seasonFields
            }
          },
          result: uniq([...state.data.result, ...action.payload.result])
        }
      };
    case ADD_SEASON_FIELD_LOADING:
      return {
        ...state,
        addSeasonFieldLoading: true,
        addSeasonFieldError: null,
        addSeasonFieldSuccess: false
      };
    case RESET_ADD_SEASON_FIELD:
      return {
        ...state,
        addSeasonFieldLoading: false,
        addSeasonFieldError: null,
        addSeasonFieldSuccess: true
      };
    case ADD_SEASON_FIELD_ERROR:
      return {
        ...state,
        addSeasonFieldLoading: false,
        addSeasonFieldError: action.error,
        addSeasonFieldSuccess: true
      };
    case DELETE_SEASON_FIELD:
      let itemIdsToDelete = state.data.result.filter((id) => id.includes(action.payload.seasonId));
      let itemIdsToStore = state.data.result.filter((id) => !id.includes(action.payload.seasonId));
      let deletedSeasonFields = omit(state.data.entities.seasonFields, [...itemIdsToDelete]);
      return {
        ...state,
        data: {
          entities: { seasonFields: deletedSeasonFields },
          result: itemIdsToStore
        }
      };
    case EDIT_SEASON_FIELD_SUCCESS:
      let details = state.data.result.filter((id) => !id.includes(action.payload.id));
      let detailsToBeRemoved = state.data.result.filter((id) => id.includes(action.payload.id));
      let updated = omit(state.data.entities.seasonFields, [...detailsToBeRemoved]);
      return {
        ...state,
        data: {
          entities: {
            seasonFields: {
              ...updated,
              ...action.payload.data.entities.seasonFields
            }
          },
          result: uniq([...details, ...action.payload.data.result])
        },
        addSeasonFieldLoading: false,
        addSeasonFieldError: null,
        addSeasonFieldSuccess: false
      };
    default:
      return state;
  }
};
