import {
  TOGGLE_HEADER,
  SET_SELECTED_ORG,
  SET_SELECTED_PROPERTY,
  SET_SELECTED_SEASONS
} from '../actions/types';

const initialState = {
  collapsed: true,
  selectedOrganization: null,
  selectedProperty: null,
  selectedSeasons: null
};

export const header = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_HEADER:
      return {
        ...state,
        collapsed: (action.payload !== null && action.payload) || !state.collapsed
      };

    case SET_SELECTED_ORG:
      return {
        ...state,
        selectedOrganization: action.payload
      };

    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload
      };

    case SET_SELECTED_SEASONS:
      const selectedSeasons = action.payload || [];
      return {
        ...state,
        selectedSeasons
      };

    default:
      return state;
  }
};
