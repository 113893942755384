import styled from 'styled-components';
import get from 'lodash/get';

export const StyledRoot = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  height: 100%;
  min-height: 100%;
  * {
    box-sizing: border-box;
  }
`;
