import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGIN_REQUEST_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  success: false
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: null
      };
    case USER_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null
      };
    case USER_LOGIN_REQUEST_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
