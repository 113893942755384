import {
  FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS,
  FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  fieldsBySeasonsPropId: []
};

export const fields = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIELDS_BY_SEASONS_PROP_ID_LOADING:
      return {
        ...state,
        loading: true
      };
    case FETCH_FIELDS_BY_SEASONS_PROP_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        fieldsBySeasonsPropId: action.payload
      };

    default:
      return state;
  }
};
