import { iff } from '../utils/iff';

export const cropColors = (name = '') => {
  const colors = {
    acerolacherry: '#FFC2B2',
    castor: '#FE7BA3',
    dragon: '#F42D69',
    oats: '#FFD26D',
    pepper: '#34CC1B',
    tangerine: '#FFB388',
    alfalfa: '#0FA97B',
    'coffee-bean': '#BE8818',
    eucalyptus: '#3C8FA1',
    onion: ' #93A90F',
    plum: '#2680E1',
    tobacco: '#9D731D',
    apple: '#F1552F',
    cherry: '#F84A7F',
    grape: '#882D8E',
    orange: '#FD833F',
    potato: '#F1A911',
    tomato: '#DF4C29',
    atemoya: '#3F9EB2',
    chia: '#474747',
    garlic: '#B521BF',
    palmTree: '#1FD29D',
    rice: '#969696',
    turnip: '#F998FF',
    avocado: '#1ABD8C',
    chickpaeas: '#48B0C6',
    lychee: '#FFDE93',
    papaya: '#FB9C67',
    rubberTree: '#606060',
    vicia: '#D10EDE',
    banana: '#FCC74F',
    citrus: '#9BFC8C',
    lettuce: ' #2DBC15',
    passionFruit: '#FC6190',
    soybeans: '#F1A911',
    watermelon: '#F56E4D',
    barley: '#808080',
    colza: '#156ECD',
    mango: '#FFCE60',
    pasture: '#59F240',
    sorghum: '#FFC5A4',
    wheat: '#F9C65C',
    bean: '#D29619',
    corn: '#A3BC10',
    millet: '#FFD7C0',
    peanut: '#87641E',
    strawberry: '#D81F57',
    blueberry: '#0E59AA',
    cotton: '#A8A8A8',
    macadamia: '#FFD8CE',
    peach: '#F99981',
    sugarcane: '#25A90F',
    brachiaria: '#29E3AC',
    crotalaria: '#3DEFBA',
    melon: '#DAFA20',
    pear: '#E9FF69',
    sunflower: '#F5BD3C',
    lemon: '#9BFC8C',
    oilseed_rape: '#DFC400',
    aspargo: '#61B49C',
    '': '#C1C5C8'
  };
  return iff(Object.keys(colors).indexOf(name) !== -1, colors[name], colors['']);
};
