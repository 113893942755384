import {
  FETCH_ALL_FIELDS_BY_ORG_SUCCESS,
  FETCH_ALL_FIELDS_BY_ORG_LOADING,
  FETCH_ALL_FIELDS_BY_ORG_ERROR,
  UPDATE_EXISTING_PROPERTY_IDS,
  RESET_ALL_FIELDS
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  data: [],
  propertiesIDs: [],
  propertyFieldEntities: {}
};

export const allFields = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_FIELDS_BY_ORG_LOADING:
      return {
        ...state,
        error: null,
        loading: true
      };
    case UPDATE_EXISTING_PROPERTY_IDS:
      return {
        ...state,
        propertiesIDs: action.payload
      };
    case 'UPDATE_PROPERTIES_FIELDS_ENTITIES':
      return {
        ...state,
        loading: false,
        error: null,
        propertyFieldEntities: action.payload
      };
    case FETCH_ALL_FIELDS_BY_ORG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload
      };
    case FETCH_ALL_FIELDS_BY_ORG_ERROR:
      return {
        ...initialState,
        data: action.err
      };
    case RESET_ALL_FIELDS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
