import { createSelector } from 'reselect';
import { iff } from '../utils/iff';

export const isPropertiesListLoading = (state) => {
  return state.propertiesList.loading;
};

export const isPropertiesListError = (state) => {
  return state.propertiesList.error;
};

export const getPropertyList = (state) => {
  return state.propertiesList.data || {};
};

export const getPropertyListEntities = createSelector(getPropertyList, (propertiesList) => {
  return propertiesList.entities || {};
});

export const getPropertyListResult = createSelector(
  getPropertyList,
  (propertyList) => propertyList.result || []
);

export const getPropertiesByOrgId = createSelector(
  getPropertyListEntities,
  getPropertyListResult,
  (_, props) => props.orgId,
  (entities = {}, result = [], orgId = '') => {
    let orgProperties = [];
    result.forEach((id) => {
      if (entities.properties[id].org_id === orgId) {
        orgProperties.push(entities.properties[id]);
      }
    });
    return orgProperties;
  }
);

export const getAllProperties = createSelector(
  getPropertyListEntities,
  getPropertyListResult,
  (entities = {}, result = []) => result.map((id) => entities.properties[id])
);

export const getPropertiesIDsByOrgId = createSelector(
  getPropertyListEntities,
  getPropertyListResult,
  (_, props) => props.orgId,
  (entities = {}, result = [], orgId = '') => {
    let orgProperties = [];
    result.forEach((id) => {
      if (entities.properties[id].org_id === orgId) {
        orgProperties.push(id);
      }
    });
    return orgProperties;
  }
);

export const getPropertyById = createSelector(
  getPropertyListEntities,
  getPropertyListResult,
  (_, props) => ({ propId: props.propId }),
  (entities = {}, result = [], { propId } = '') =>
    iff(result.find((id) => id === propId) !== undefined, () => entities.properties[propId], {})
);
