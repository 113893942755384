import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { iff } from '../../utils/iff';
import { FontIcon } from './IconComponent';

export const FooterNotification = (props) => {
  const [isChrome, setIsChrome] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('isChrome') === 'true') {
      setIsChrome(true);
    } else {
      setIsChrome(sessionStorage.getItem('isChrome'));
    }
  }, [isChrome]);

  const handleOkButton = () => {
    localStorage.setItem('isChrome', true);
    setIsChrome(true);
  };

  const handleCloseClick = () => {
    sessionStorage.setItem('isChrome', true);
    setIsChrome(true);
  };

  return (
    <>
      {iff(
        props.isChrome === false && (isChrome === null || isChrome === ''),
        <div
          className='responsive-msg footer-brower-msg'
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p>{t('Please try Chrome Browser, our application runs smooth on it')}</p>
          <button style={{ minWidth: 'auto' }} onClick={handleOkButton}>
            {t(`Don't show this message again`)}
          </button>
          <button
            onClick={handleCloseClick}
            className='footer-close-btn'
            style={{ minWidth: '25px', background: 'transparent' }}>
            <FontIcon name='close-glyph' size={12} color='#fff' />
          </button>
        </div>
      )}
      {iff(
        props.isMobile && ['local', 'qa'].includes(process.env.REACT_APP_envName),
        <div
          className='responsive-msg responsive-msg-low-resolution'
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <p>
            {t(
              'Cropwise Base works even better on the app. Use the app to get the full experience.'
            )}
          </p>
          <button>{t('Switch to the app')}</button>
        </div>
      )}
    </>
  );
};

FooterNotification.propTypes = {
  isMobile: PropTypes.bool,
  isChrome: PropTypes.bool
};
