import {
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_LOADING,
  FETCH_USER_PROFILE_ERROR
} from '../actions/types';

const initialState = {
  loading: false,
  error: null,
  success: false,
  userProfile: {
    authorities: []
  }
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        success: false
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        userProfile: action.payload
      };
    case FETCH_USER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload
      };

    default:
      return state;
  }
};
