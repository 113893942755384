import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import {
  SET_ORGANIZATIONS_LIST_LOADING,
  SET_ORGANIZATIONS_LIST_SUCCESS,
  SET_ORGANIZATIONS_LIST_ERROR,
  CREATE_ORG_LIST_SUCCESS,
  CREATE_ORG_LIST_LOADING,
  CREATE_ORG_LIST_ERROR,
  EDIT_ORG_LIST_SUCCESS,
  EDIT_ORG_LIST_ERROR,
  EDIT_ORG_LIST_LOADING,
  DELETE_ORG_LIST_ITEM_RESET,
  DELETE_ORG_LIST_ITEM_LOADING,
  DELETE_ORG_LIST_ITEM_SUCCESS,
  DELETE_ORG_LIST_ITEM_ERROR,
  UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING,
  UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS,
  UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR,
  REMOVE_ORG_LIST_ITEM_AVATAR_LOADING,
  REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS,
  REMOVE_ORG_LIST_ITEM_AVATAR_ERROR
} from '../actions/types';

let initialState = {
  data: {
    entities: {},
    result: []
  },
  error: null,
  loading: false,
  createOrgLoading: false,
  createOrgError: null,
  editOrgLoading: false,
  editOrgError: null,
  deleteOrgLoading: false,
  deleteOrgError: null,
  uploadOrgAvatarLoading: false,
  uploadOrgAvatarSuccess: false,
  uploadOrgAvatarError: null,
  removeOrgAvatarLoading: false,
  removeOrgAvatarSuccess: false,
  removeOrgAvatarError: null,
  isFetchOrgListSuccess: false
};

export const orgsList = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATIONS_LIST_LOADING:
      return {
        ...initialState,
        loading: true,
        isFetchOrgListSuccess: false
      };
    case SET_ORGANIZATIONS_LIST_SUCCESS:
      return {
        ...initialState,
        data: action.payload,
        isFetchOrgListSuccess: true
      };
    case SET_ORGANIZATIONS_LIST_ERROR:
      return {
        ...initialState,
        error: action.payload,
        isFetchOrgListSuccess: false
      };
    case CREATE_ORG_LIST_LOADING:
      return {
        ...state,
        createOrgLoading: true
      };
    case CREATE_ORG_LIST_SUCCESS:
      return {
        ...initialState,
        data: {
          entities: { orgs: { ...state.data.entities.orgs, ...action.payload.entities.orgs } },
          result: [...state.data.result, action.payload.result]
        }
      };
    case CREATE_ORG_LIST_ERROR:
      return {
        ...state,
        createOrgLoading: false,
        createOrgError: action.error
      };
    case EDIT_ORG_LIST_SUCCESS:
      return {
        ...state,
        editOrgLoading: false,
        data: {
          ...state.data,
          entities: { orgs: { ...state.data.entities.orgs, ...action.payload.entities.orgs } }
        }
      };
    case EDIT_ORG_LIST_ERROR:
      return {
        ...state,
        editOrgError: action.error,
        editOrgLoading: false
      };
    case EDIT_ORG_LIST_LOADING:
      return {
        ...state,
        editOrgLoading: true
      };
    case DELETE_ORG_LIST_ITEM_RESET:
      return {
        ...state,
        deleteOrgSuccess: false,
        deleteOrgLoading: false,
        deleteOrgError: null
      };
    case DELETE_ORG_LIST_ITEM_LOADING:
      return {
        ...state,
        deleteOrgLoading: true,
        deleteOrgError: null,
        deleteOrgSuccess: false
      };
    case DELETE_ORG_LIST_ITEM_SUCCESS:
      let updatedOrgs = omit(state.data.entities.orgs, [action.payload.id]);
      let result = state.data.result.filter((id) => id !== action.payload.id);
      return {
        ...state,
        deleteOrgLoading: false,
        deleteOrgError: null,
        deleteOrgSuccess: true,
        data: {
          entities: { orgs: updatedOrgs },
          result: result
        }
      };
    case DELETE_ORG_LIST_ITEM_ERROR:
      return {
        ...state,
        deleteOrgLoading: false,
        deleteOrgSuccess: false,
        deleteOrgError: action.error
      };
    case UPLOAD_ORG_LIST_ITEM_AVATAR_LOADING:
      return {
        ...state,
        uploadOrgAvatarLoading: true,
        uploadOrgAvatarSuccess: false,
        uploadOrgAvatarError: null
      };

    case UPLOAD_ORG_LIST_ITEM_AVATAR_ERROR:
      return {
        ...state,
        uploadOrgAvatarLoading: false,
        uploadOrgAvatarError: action.error
      };

    case UPLOAD_ORG_LIST_ITEM_AVATAR_SUCCESS:
      let updatedAvatar = cloneDeep(state.data.entities.orgs[action.payload.org.id]);
      updatedAvatar.picture_url = action.payload.res.data.avatar_url;
      let updatedAvatarOrgs = {
        ...omit(state.data.entities.orgs, [action.payload.org.id]),
        [action.payload.org.id]: updatedAvatar
      };

      return {
        ...state,
        data: {
          ...state.data,
          entities: { orgs: { ...updatedAvatarOrgs } }
        },
        uploadOrgAvatarLoading: false,
        uploadOrgAvatarSuccess: true
      };

    case REMOVE_ORG_LIST_ITEM_AVATAR_LOADING:
      return {
        ...state,
        removeOrgAvatarLoading: true,
        removeOrgAvatarSuccess: false,
        removeOrgAvatarError: null
      };

    case REMOVE_ORG_LIST_ITEM_AVATAR_ERROR:
      return {
        ...state,
        removeOrgAvatarLoading: false,
        removeOrgAvatarError: action.error
      };

    case REMOVE_ORG_LIST_ITEM_AVATAR_SUCCESS:
      let removedAvatar = cloneDeep(state.data.entities.orgs[action.payload.id]);
      removedAvatar.picture_url = null;
      let removedAvatarOrgs = {
        ...omit(state.data.entities.orgs, [action.payload.id]),
        [action.payload.id]: removedAvatar
      };

      return {
        ...state,
        data: {
          ...state.data,
          entities: { orgs: { ...removedAvatarOrgs } }
        },
        removeOrgAvatarLoading: false,
        removeOrgAvatarSuccess: true
      };
    default:
      return state;
  }
};
