import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { StyledModal } from './InfoCarousel.style';
import { InfoCarouselComponent } from './InfoCarousel';
import { iff } from '../../utils/iff';

export const InfoCarouselModal = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const handleCompleteTutorial = (cb) => {
    const userDeails = { ...props.user, opt_ins: undefined, tutorial_complete: true };
    props.updateProfileSettings(userDeails, props.user.id);
    setModalVisible(false);
    sessionStorage.setItem(`tutorial_complete_${props.user.id}`, true);
    if (cb) {
      cb(); // callback
    }
  };
  useEffect(() => {
    const sessionTutorialComplete = sessionStorage.getItem(`tutorial_complete_${props.user.id}`);
    if (!sessionTutorialComplete && props.termsSuccess === true) {
      setModalVisible(get(props.user, 'tutorial_complete') === false);
    }
  }, [props.user, props.termsSuccess]);
  return (
    <>
      <StyledModal
        centered
        width={592}
        footer={null}
        visible={modalVisible}
        closeIcon={iff(props.isNewSignedupUser, () => (
          <></>
        ))}
        onCancel={() => {
          if (!props.isNewSignedupUser) {
            handleCompleteTutorial();
          }
        }}
        maskClosable={false}>
        <InfoCarouselComponent {...props} handleCompleteTutorial={handleCompleteTutorial} />
      </StyledModal>
    </>
  );
};

InfoCarouselModal.propTypes = {
  user: PropTypes.object,
  isNewSignedupUser: PropTypes.bool,
  updateProfileSettings: PropTypes.func,
  termsSuccess: PropTypes.bool.isRequired
};
