import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getAreaByUnit, getUnitText } from '../utils/getAreaByUnit';
import { getCropById } from './cropsSelector';
import { isSeasonsListLoading, isSeasonsListError, getSeasonsByOrgId } from './seasonsSelector';
import {
  isPropertiesListLoading,
  isPropertiesListError,
  getPropertiesByOrgId
} from './propertiesSelector';
import { iff } from '../utils/iff';

export const isOrgListLoading = (state) => {
  return state.orgsList.loading;
};

export const isOrgListError = (state) => {
  return state.orgsList.error;
};

export const isOrgListSuccesss = (state) => {
  return state.orgsList.isFetchOrgListSuccess;
};

export const getOrgsList = (state) => {
  return (state.orgsList && state.orgsList.data) || {};
};

export const getOrgsListEntities = createSelector(getOrgsList, (orgsList) => {
  return orgsList.entities || {};
});

export const getOrgsListResult = createSelector(getOrgsList, (orgsList) => orgsList.result || []);

export const getallOrgsList = createSelector(
  getOrgsListEntities,
  getOrgsListResult,
  (entities = {}, result = []) => result.map((rId) => entities.orgs[rId])
);

export const getOrgListById = createSelector(
  getOrgsListEntities,
  getOrgsListResult,
  (_, props) => props.orgId,
  (entities = {}, result = [], orgId = '') => {
    return iff(
      result.find((id) => id === orgId) !== undefined,
      () => entities.orgs[orgId],
      () => ''
    );
  }
);

// const formatAddressString = ({ address, city, zip_code, state, country }) => {
//   let finalStr = '';
//   let arr = [];

//   if (address) {
//     arr.push(address);
//   }
//   if (city) {
//     arr.push(city);
//   }
//   finalStr += ` ${arr.join(', ')}`;

//   if (zip_code) {
//     finalStr += `, ${zip_code} - `;
//   } else if (!finalStr) {
//     finalStr += ', ';
//   }
//   arr = [];

//   if (state) {
//     arr.push(state);
//   }
//   if (country) {
//     arr.push(country);
//   }
//   finalStr += ` ${arr.join(', ')}`;

//   return finalStr;
// };

export const getOrgsDetails = createSelector(
  getOrgsListEntities,
  (state, props) => {
    return { orgId: props.orgId, state };
  },
  (entities = {}, { orgId, state }) => {
    let { name, id, unit_system } = entities.orgs[orgId] || {};
    let orgProperties = getPropertiesByOrgId(state, { orgId }) || [];
    let totalArea = orgProperties.reduce((total, { total_area = 0 }) => total + total_area, 0);
    let orgSeasons = getSeasonsByOrgId(state, { orgId }) || [];
    let CropsSet = orgSeasons.map((season) => getCropById(state, { cropId: season.crop_id }));

    return {
      name: name,
      id: id,
      // address: formatAddressString(entities.orgs[orgId]),
      address: get(entities.orgs[orgId], 'address', ''),
      totalArea: {
        area: getAreaByUnit(totalArea, unit_system),
        unit: getUnitText(unit_system)
      },
      noOfProperties: (orgProperties && Array.isArray(orgProperties) && orgProperties.length) || 0,
      crops: CropsSet
    };
  }
);

export const isOrgPropSeasonLoading = createSelector(
  isOrgListLoading,
  isPropertiesListLoading,
  isSeasonsListLoading,
  (orgLoading, propLoading, seasonLoading) => {
    return orgLoading || propLoading || seasonLoading;
  }
);

export const isOrgPropLoading = createSelector(
  isOrgListLoading,
  isPropertiesListLoading,
  isSeasonsListLoading,
  (orgLoading, propLoading, seasonLoading) => {
    return orgLoading || propLoading || seasonLoading;
  }
);

export const isOrgPropSeasonError = createSelector(
  isOrgListError,
  isPropertiesListError,
  isSeasonsListError,
  (orgError, propError, seasonError) => {
    return orgError || propError || seasonError;
  }
);
