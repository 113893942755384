import { createSelector } from 'reselect';
import get from 'lodash/get';
import { cropColors } from '../utils/crop-color';
import { getCropById } from './cropsSelector';
import { iff } from '../utils/iff';

//Seasons Selectors
export const getSeasons = (state) => state.seasonsList;

export const isSeasonsListLoading = (state) => {
  return state.seasonsList.loading;
};

export const isSeasonsListError = (state) => {
  return state.seasonsList.error;
};

export const getseasonsList = (state) => {
  return state.seasonsList.data || {};
};

export const getSeasonsListEntities = createSelector(
  getseasonsList,
  (seasonsList) => seasonsList.entities || {}
);

export const getSeasonsListResult = createSelector(
  getseasonsList,
  (seasonsList) => seasonsList.result || []
);

export const getSeasonsByOrgId = createSelector(
  getSeasonsListEntities,
  getSeasonsListResult,
  (_, props) => props.orgId,
  (entities = {}, result = [], orgId = '') => {
    let orgSeason = [];
    result.forEach((id) => {
      if (entities.seasons[id].org_id === orgId) {
        orgSeason.push(entities.seasons[id]);
      }
    });
    return orgSeason;
  }
);

export const getAllSeasons = createSelector(
  getSeasonsListEntities,
  getSeasonsListResult,
  (entities = {}, result = []) => result.map((id) => entities.seasons[id])
);

export const getSeasonById = createSelector(
  getSeasonsListEntities,
  getSeasonsListResult,
  (_, props) => ({ seasonId: props.seasonId }),
  (entities = {}, result = [], { seasonId } = '') => {
    return iff(
      result.find((id) => id === seasonId) !== undefined,
      () => entities.seasons[seasonId],
      {}
    );
  }
);

//seasons properites relationship
export const getPropertiesBySeasons = (state) => get(state, 'propertiesBySeasonList', {});

export const isPropertiesBySeasonsLoading = createSelector(
  getPropertiesBySeasons,
  (properties) => properties.loading
);

export const isPropertiesBySeasonsError = createSelector(
  getPropertiesBySeasons,
  (properties) => properties.error
);

export const getPropertiesBySeasonsData = createSelector(getPropertiesBySeasons, (properties) =>
  get(properties, 'data.entities.propertiesBySeasonId', {})
);

export const getPropertiesBySeasonsIds = createSelector(getPropertiesBySeasons, (properties) =>
  get(properties, 'data.result', [])
);

export const getSeasonsIdsByPropertyId = createSelector(
  getPropertiesBySeasonsIds,
  (_, props) => props.propertyId,
  (propertyIdsWithSeasonId, id) => {
    return propertyIdsWithSeasonId.filter((propertyIds) => {
      return propertyIds.includes(id);
    });
  }
);

export const getSeasonsByPropertyId = createSelector(
  getSeasonsIdsByPropertyId,
  getSeasonsByOrgId,
  (seasonByPropId, seasons) => {
    return seasonByPropId.map((id) => {
      const seasonId = id.split(':');
      return seasons.find((season) => season.id === seasonId[1]);
    });
  }
);

//season-fields relationship
export const getSeasonFields = (state) => get(state, 'seasonFields', {});

export const isSeasonFieldsLoading = createSelector(getSeasonFields, (seasons) => seasons.loading);

export const isSeasonFieldsError = createSelector(getSeasonFields, (seasons) => seasons.error);

export const getSeasonFieldsData = createSelector(getSeasonFields, (seasons) =>
  get(seasons, 'data.entities.seasonFields', {})
);

export const getSeasonFieldsIds = createSelector(getSeasonFields, (seasons) =>
  get(seasons, 'data.result', [])
);

export const getSeasonsIdsByFieldId = createSelector(
  getSeasonFieldsIds,
  (_, props) => props.fieldId,
  (seasonWithFieldId, id) => {
    return seasonWithFieldId.filter((fieldId) => {
      return fieldId.includes(id);
    });
  }
);

export const getSeasonsByFieldId = createSelector(
  getSeasonsIdsByFieldId,
  getSeasonsByOrgId,
  (seasonByFieldId, seasons) => {
    let seasonByField = null;
    for (const item of seasonByFieldId) {
      const seasonId = item.split(':');
      for (const season of seasons) {
        if (season.id === seasonId[1]) {
          seasonByField = season;
          break;
        }
      }
      if (seasonByField) {
        break;
      }
    }
    return seasonByField;
  }
);

export const getActiveSeasonsByFieldId = createSelector(
  getSeasonsIdsByFieldId,
  getSeasonsByOrgId,
  (seasonByFieldId, seasons) => {
    let activeSeason = null;
    for (const item of seasonByFieldId) {
      const seasonId = item.split(':');
      for (const season of seasons) {
        if (season.id === seasonId[1] && season.active) {
          activeSeason = season;
          break;
        }
      }
      if (activeSeason) {
        break;
      }
    }
    return activeSeason;
  }
);

export const getActiveSeasonFieldWithCropColor = createSelector(
  getActiveSeasonsByFieldId,
  (state, props) => ({ state: state, field_details: props.field_details }),
  (season, { state, field_details }) => {
    let color = iff(
      season !== undefined && season !== null,
      () => getCropById(state, { cropId: season.crop_id }).wk_slug,
      () => ''
    );
    return {
      ...field_details,
      season_details: season,
      color: cropColors((color && color.toLowerCase()) || '')
    };
  }
);

export const getSeasonFieldsByOrgId = createSelector(
  getSeasonFieldsIds,
  getSeasonFieldsData,
  getSeasonsByOrgId,
  (_, props) => ({ seasonID: props.seasonId, orgId: props.orgId }),
  (seasonByFieldId, fieldsDetails, seasons) => {
    let seasonByField = [];
    for (const item of seasonByFieldId) {
      const seasonId = item.split(':');
      if (seasons.find((season) => season.id === seasonId[1]) !== undefined) {
        seasonByField.push(fieldsDetails[item]);
      }
    }
    return seasonByField;
  }
);

//create and edit season
export const isCreateSeasonLoading = createSelector(
  getSeasons,
  (state) => state.createSeasonLoading
);

export const isCreateSeasonError = createSelector(getSeasons, (state) => state.createSeasonError);

export const isCreateSeasonSuccess = createSelector(
  getSeasons,
  (state) => state.createSeasonSuccess
);

export const isEditSeasonLoading = createSelector(getSeasons, (state) => state.editSeasonLoading);

export const isEditSeasonError = createSelector(getSeasons, (state) => state.editSeasonError);

export const isEditSeasonSuccess = createSelector(getSeasons, (state) => state.editSeasonSuccess);

export const isDeleteSeasonLoading = createSelector(
  getSeasons,
  (state) => state.deleteSeasonLoading
);

export const isDeleteSeasonError = createSelector(getSeasons, (state) => state.deleteSeasonError);

export const isDeleteSeasonSuccess = createSelector(
  getSeasons,
  (state) => state.deleteSeasonSuccess
);
