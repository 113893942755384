export const AppConstants = {
  TERM_AND_CONDITIONS: '',
  ENVIRONMENT: process.env.REACT_APP_envName,
  DATE_FORMATS: {
    YYYY_DASH_MM_DASH_DD_WITH_TIME: `yyyy-MM-dd'T'HH:mm:ss'Z'`,
    MMM_SPACE_YEAR: `MMM yyyy`,
    YYYY_DASH_MM_DASH_DD: 'YYYY-MM-DD',
    YYYY_DASH_MM_DASH_DDTHH_COLON_mm_COLON_ss: 'YYYY-MM-DDTHH:mm:ss',
    dddd_SPACE_DD_SPACE_MMM: 'dddd DD MMM'
  },
  SUPPORTED_LANGS: [
    { value: 'en', name: 'English (USA)', shortLabel: 'EN' },
    { value: 'pt-BR', name: 'Portuguese', shortLabel: 'PT' },
    { value: 'fr', name: 'French', shortLabel: 'FR' },
    { value: 'de', name: 'German', shortLabel: 'DE' },
    { value: 'pl', name: 'Polish', shortLabel: 'PL' },
    { value: 'es', name: 'Spanish', shortLabel: 'ES' }
  ],
  VALIDATIONS: {
    MOBILE_DIGITS_LENGTH: 20,
    MIN_MOBILE_DIGITS_LENGTH: 8,
    NAME_MAX_LENGTH: 50,
    COMMENTS_MAX_LENGTH: 100,
    ZIP_CODE_MAX_LENGTH: 15,
    CITY_MAX_LENGTH: 50,
    COUNTRY_MAX_LENGTH: 25,
    STATE_MAX_LENGTH: 50,
    FIELD_NAME_MAX_LENGTH: 25,
    EIN_MAX_LENGTH: 20,
    ADDRESS_MAX_LENGTH: 100,
    SEASONS_NAME_LENGTH: 50
  },
  EMAIL_MAX_LENGTH: 352,
  PASSWORD_MAX_LENGTH: 50,
  ADD_FIELDS: {
    SEARCH_NAME_MAX_LENGTH: 25
  },
  DATE_PERIOD: {
    DATE_FORMAT: 'MMM DD, YYYY',
    NINE: 9,
    ONE: 1,
    SIX: 6,
    THREE_SIXTY_FIVE: 365,
    THREE_ZERO: 30,
    YEARS: 'years',
    MONTHS: 'months',
    DAYS: 'days'
  },
  CLOCK_FORMAT: {
    FORMAT_12: 'FORMAT_12',
    FORMAT_24: 'FORMAT_24'
  },
  DATE_FORMATS_SETTINGS: ['DD-MM-YYYY', 'DD-MM-YY', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MMM-YYYY'],
  getTimeConstants: (t) => ({
    [AppConstants.CLOCK_FORMAT.FORMAT_12]: [
      '12:00 PM',
      '03:00 PM',
      '06:00 PM',
      '09:00 PM',
      '12:00 AM',
      '03:00 AM',
      '06:00 AM',
      '09:00 AM'
    ],
    [AppConstants.CLOCK_FORMAT.FORMAT_24]: [
      t('MIDDAY'),
      '15:00',
      '18:00',
      '21:00',
      t('MIDNIGHT'),
      '03:00',
      '06:00',
      '09:00'
    ]
  }),
  TASK_STATUS: (status, primaryStatus, t) => {
    const mapping = {
      PENDING: { text: t('Pending'), color: '#707374' },
      DELAYED: { text: t('Delayed'), color: '#F74141' }
    };
    return mapping[status] || primaryStatus;
  },
  TASK_DEFAULT_STATUS_COLOR: (status) =>
    ({
      Planned: '#707374',
      'In progress': '#EB8205',
      Complete: '#2FB966'
    }[status]),
  GRAPH_DEFAULT_COLORS: ['#EB8205', '#F9C43D', '#00A0BE'],
  O_AUTH: {
    responseCode: 'code',
    clientId: 'strix-ui'
  },
  DEFAULT_PERMISSIONS: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      {
        scope: 'WRITE',
        name: 'PROPERTIES'
      },
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'INVENTORY'
      },
      {
        scope: 'WRITE',
        name: 'INVENTORY'
      },
      {
        scope: 'READ',
        name: 'FINANCIAL'
      },
      {
        scope: 'READ',
        name: 'SEASON_AREA'
      },
      {
        scope: 'WRITE',
        name: 'SEASON_AREA'
      }
    ]
  },
  WRITE_PERMISSION: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'WRITE',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'WRITE',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      {
        scope: 'WRITE',
        name: 'PROPERTIES'
      },
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'WRITE',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'USERS'
      },
      {
        scope: 'WRITE',
        name: 'OWNER'
      }
    ]
  },
  READ_PERMISSION: {
    permissions: [
      {
        scope: 'READ',
        name: 'ORG'
      },
      {
        scope: 'READ',
        name: 'ASSIGNEES'
      },
      {
        scope: 'WRITE',
        name: 'ASSIGNEES'
      },
      {
        scope: 'READ',
        name: 'FARMSHOTS'
      },
      {
        scope: 'READ',
        name: 'VENDORS'
      },
      {
        scope: 'READ',
        name: 'PROPERTIES'
      },
      {
        scope: 'WRITE',
        name: 'PROPERTIES'
      },
      {
        scope: 'READ',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'WRITE',
        name: 'EQUIPMENTS'
      },
      {
        scope: 'READ',
        name: 'PRODUCTS'
      },
      {
        scope: 'WRITE',
        name: 'PRODUCTS'
      },
      {
        scope: 'READ',
        name: 'SEASONS'
      },
      {
        scope: 'READ',
        name: 'SUPPLIES'
      },
      {
        scope: 'WRITE',
        name: 'SUPPLIES'
      },
      {
        scope: 'READ',
        name: 'USERS'
      },
      {
        scope: 'READ',
        name: 'OWNER'
      }
    ]
  }
};
