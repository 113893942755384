/**
 * i18n : https://react.i18next.com/latest/using-with-hooks
 */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from 'moment';
import { getLanguageCode } from './utils/common-methods';

const lng =
  getLanguageCode(localStorage.getItem('i18nextLngTemp')) ||
  localStorage.getItem('i18nextLng') ||
  'en';
moment.locale(lng);

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: lng,
    react: {
      useSuspense: false
    },
    debug: process.env.node_env !== 'production',
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/i18n/{{lng}}.json'
    }
  })
  .catch(() => {
    // console.log('Fail initing i18n');
  });

// eslint-disable-next-line
export default i18n;
