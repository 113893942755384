import {
  FETCH_TASKS_ERROR,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_LOADING,
  FETCH_TASKS_CATELOGS_LOADING,
  FETCH_TASKS_CATELOGS_SUCCESS,
  FETCH_TASKS_CATELOGS_ERROR,
  DELETE_TASK_LOADING,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_ERROR,
  ADD_NEW_TASK_LOADING,
  ADD_NEW_TASK_SUCCESS,
  ADD_NEW_TASK_ERROR,
  UPDATE_TASK_LOADING,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_ERROR,
  RESET_CREATE_UPDATE_TASK_STORE
} from '../actions/types';
import { iff } from '../utils/iff';

const initialState = {
  tasksLoading: false,
  tasksError: null,
  catelogLoading: false,
  catelogError: null,
  catelogSuccess: false,
  deleteTaskLoading: false,
  deleteTaskError: null,
  deleteTaskSuccess: false,
  addNewTaskLoading: false,
  addNewTaskError: null,
  addNewTaskSuccess: false,
  updateTaskLoading: false,
  updateTaskError: null,
  updateTaskSuccess: false,
  userTasks: [],
  workers: {},
  equipments: {},
  products: {},
  categories: {},
  taskStatuses: {}
};

export const tasks = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TASKS_LOADING:
      return {
        ...state,
        tasksLoading: true,
        tasksError: null
      };
    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        tasksLoading: false,
        tasksError: null,
        userTasks: action.payload
      };
    case FETCH_TASKS_ERROR:
      return {
        ...state,
        tasksLoading: false,
        tasksError: action.error
      };

    case FETCH_TASKS_CATELOGS_LOADING:
      return {
        ...state,
        catelogLoading: true,
        catelogSuccess: false,
        catelogError: null
      };
    case FETCH_TASKS_CATELOGS_SUCCESS:
      const { categories, products, taskStatuses, workers, equipments } = action.payload;
      return {
        ...state,
        catelogLoading: false,
        catelogSuccess: true,
        catelogError: null,
        categories,
        products,
        taskStatuses,
        workers,
        equipments
      };
    case FETCH_TASKS_CATELOGS_ERROR:
      return {
        ...state,
        catelogLoading: false,
        catelogSuccess: false,
        catelogError: action.error
      };

    case DELETE_TASK_LOADING:
      return {
        ...state,
        deleteTaskLoading: true,
        deleteTaskError: null,
        deleteTaskSuccess: false
      };
    case DELETE_TASK_SUCCESS:
      const filteredTasks = state.userTasks.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        deleteTaskLoading: false,
        deleteTaskError: null,
        deleteTaskSuccess: true,
        userTasks: filteredTasks
      };
    case DELETE_TASK_ERROR:
      return {
        ...state,
        deleteTaskLoading: false,
        deleteTaskError: action.error,
        deleteTaskSuccess: false
      };

    case ADD_NEW_TASK_LOADING:
      return {
        ...state,
        addNewTaskLoading: true,
        addNewTaskError: null,
        addNewTaskSuccess: false
      };
    case ADD_NEW_TASK_SUCCESS:
      return {
        ...state,
        addNewTaskLoading: false,
        addNewTaskError: null,
        addNewTaskSuccess: true,
        userTasks: [...state.userTasks, action.payload]
      };
    case ADD_NEW_TASK_ERROR:
      return {
        ...state,
        addNewTaskLoading: false,
        addNewTaskError: action.error,
        addNewTaskSuccess: false
      };
    case UPDATE_TASK_LOADING:
      return {
        ...state,
        updateTaskLoading: true,
        updateTaskError: null,
        updateTaskSuccess: false
      };
    case UPDATE_TASK_SUCCESS:
      const updatedTasks = state.userTasks.map((task) =>
        iff(task.id === action.payload.id, { ...action.payload }, task)
      );

      return {
        ...state,
        updateTaskLoading: false,
        updateTaskError: null,
        updateTaskSuccess: true,
        userTasks: updatedTasks
      };
    case UPDATE_TASK_ERROR:
      return {
        ...state,
        updateTaskLoading: false,
        updateTaskError: action.error,
        updateTaskSuccess: false
      };
    case RESET_CREATE_UPDATE_TASK_STORE:
      return {
        ...state,
        updateTaskLoading: false,
        updateTaskError: null,
        updateTaskSuccess: false,
        addNewTaskLoading: false,
        addNewTaskSuccess: false,
        addNewTaskError: null
      };
    default:
      return state;
  }
};
