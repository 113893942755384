/* eslint-disable no-mixed-operators */
import { isEmpty } from 'lodash';
import { getTimes } from 'suncalc2';
import moment from 'moment';

import { iff } from '../../utils/iff';

export const toFixed = (number, fractionDigits) => Number(number).toFixed(fractionDigits);

export function padNumber(n) {
  if (n < 9) {
    return `0${n}`;
  }
  return n.toFixed(0);
}

export const modifyToEllipsisText = (text, maxStringLength, symbols = '...') => {
  return iff(
    !isEmpty(text) && text.length > maxStringLength,
    `${text.substr(0, maxStringLength)}${symbols}`,
    text
  );
};

export const getSprayConstraints = (constraints, t) => {
  const sprayConstraintsMap = {
    'Leaf Surface too wet': () => t('weather.sprayConstraints.1'),
    Rain: () => t('weather.sprayConstraints.2'),
    Windy: () => t('weather.sprayConstraints.3'),
    'Temperature too High': () => t('weather.sprayConstraints.4'),
    'Temperature too Low': () => t('weather.sprayConstraints.5'),
    'Multiple Risks': () => t('weather.sprayConstraints.6'),
    'Evaporation Risk': () => t('weather.sprayConstraints.7'),
    'Risk of Wind': () => t('weather.sprayConstraints.8')
  };

  if (!constraints) {
    return constraints;
  }

  return constraints
    .split(',')
    .map((item) => sprayConstraintsMap[item](), t)
    .join(',');
};

export function getSunTime(weatherCoordinates, timeZone) {
  const getDateString = (date) =>
    `${new Date(
      new Date(date).toLocaleString('en-US', { timeZone })
    ).getHours()}:${date.getMinutes()}`;

  const sunTime = getTimes(
    new Date(),
    parseFloat(weatherCoordinates.lat),
    parseFloat(weatherCoordinates.long)
  );
  const sunrise = getDateString(sunTime.sunrise);
  const sunset = getDateString(sunTime.sunset);
  return { sunrise, sunset };
}

const THREE_HUNDRED_SIXTY = 360,
  TWENTY_FOUR = 24,
  TWO = 2;

export function translate(x, y) {
  return `translate(${x}, ${y})`;
}

export function computeTriangleRotation(value) {
  return (THREE_HUNDRED_SIXTY / TWENTY_FOUR) * value + THREE_HUNDRED_SIXTY / TWENTY_FOUR / TWO;
}

export function MathRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export function hoursToAngle(time) {
  const hours = parseInt(time.split(':')[0]);
  const minutes = parseInt(time.split(':')[1]);
  return 180 + (hours * 15 + minutes * 0.25);
}

export const getWeatherColor = (i) => {
  switch (i) {
    case 0:
      return ['#2FB966', 'rgba(47,185,102, 0.15)'];
    case 1:
      return ['#FFCA28', 'rgba(255,202,40, 0.15)'];
    case 2:
      return ['#F74141', 'rgba(247,65,65, 0.15)'];
    case 3:
      return ['#FFCA28', 'rgba(255,202,40, 0.15)'];
    case 4:
      return ['#F74141', 'rgba(247,65,65, 0.15)'];
    default:
      return ['', ''];
  }
};

export const isToday = (someDate) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const getLabelFromDate = (date, t) => {
  if (!date) {
    return '';
  }
  const DAYS = [t('SUN'), t('MON'), t('TUE'), t('WED'), t('THU'), t('FRI'), t('SAT')];
  const selectedDate = new Date(date);
  return iff(
    isToday(selectedDate),
    t('TODAY'),
    `${DAYS[selectedDate.getDay()]} ${selectedDate.getDate()}`
  );
};

export const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

export const getRecommendationColor = (recommendation) => {
  if (!recommendation) {
    return '#FF9D97';
  }
  switch (recommendation.geotroll_code) {
    case 3: //red
      return ['#F74141', 'rgba(247,65,65, 0.15)'];
    case 2: //yellow
      return ['#FFCA28', 'rgba(255,202,40, 0.15)'];
    default:
      //green
      return ['#2FB966', 'rgba(47,185,102, 0.15)'];
  }
};

export const generateDailyGeoTrollCodes = (
  sprayingRecommendationsDaily,
  dateTime,
  geotrollCode
) => {
  const date = moment
    .parseZone(dateTime)
    .locale('en')
    .set('minutes', 0)
    .set('hours', 0)
    .set('seconds', 0)
    .format(dateFormat);
  sprayingRecommendationsDaily[date] = sprayingRecommendationsDaily[date] || {};
  let { oneCount = 0, twoCount = 0, threeCount = 0 } = sprayingRecommendationsDaily[date] || {};
  if (geotrollCode === 1) {
    oneCount++;
  }
  if (geotrollCode === 2) {
    twoCount++;
  }
  if (geotrollCode === 3) {
    threeCount++;
  }

  sprayingRecommendationsDaily[date] = { oneCount, twoCount, threeCount };
};

export const calcHighestWithPrecedence = (g, y, r) => {
  if ((r === y && y === g) || (r > y && r > g)) {
    return 3;
  }
  if (y > r && y > g) {
    return 2;
  }
  if (g > r && g > y) {
    return 1;
  }
  if (y === g) {
    return 2;
  }
  if (y === r || r === g) {
    return 3;
  }
};

export const getAverageDailyGeoTrollCodes = (sprayingRecommendationsDaily) => {
  Object.keys(sprayingRecommendationsDaily).forEach((key) => {
    let { oneCount = 0, twoCount = 0, threeCount = 0 } = sprayingRecommendationsDaily[key];
    sprayingRecommendationsDaily[key].geotroll_code = calcHighestWithPrecedence(
      oneCount,
      twoCount,
      threeCount
    );
  });
};
